<template>
  <h5 class="py-1 m-0  d-flex align-items-center" >
      <span class="flex-grow-1">
        {{ item.getProduct().getDisplayName() }}<span>  </span>
        <span v-if="item.isFragile()"> - <span class="bg-warning">{{$t('general.fragile')}}</span></span>
      </span>
    <span>{{ formatQty(item.getQty(), item.getProduct().getUnit()) }}</span>
  </h5>
</template>

<script>
import {NumbersMixin} from "@/mixins/GeneralMixin";
// import Button from "@/components/elements/Button";

export default {
  name: "OrderItem",
  components: {
    // Button
    },
  emits: ['click'],
  mixins: [NumbersMixin],
  props: {
    item: Object
  },
  data: function () {
    return {

    }
  },
  methods: {
  }
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>
