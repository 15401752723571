<template>

  <OrderDetailsModal :order-id="order.getId()" v-if="orderDetailsOpen && withDetailsModal" @closemodal="orderDetailsOpen = false" />

  <dl class="row mb-0">
    <dt v-if="withOrderNr" class="col-4 col-md-3 col-xl-2 mb-0 font-weight-semibold">{{$t('frontoffice.farmers.order_nr')}}</dt>
    <dd v-if="withOrderNr" class="col-8 col-md-9 col-xl-10 mb-0 font-weight-semibold">
      {{ order.getId() }} <span v-if="withShipmentDeliveryStatus"> - <i :class="{'text-success': isDelivered, 'text-danger': !isDelivered}">{{ $t(deliveryStatusText) }}</i></span>
    </dd>
    <dt v-if="hasName" class="col-4 col-md-3 col-xl-2 mb-0 font-weight-semibold">{{$t('frontoffice.farmers.pack.order.name')}}</dt>
    <dd v-if="hasName" class="col-8 col-md-9 col-xl-10 mb-0 font-weight-semibold">
      {{order.getContact()}}
    </dd>

    <br v-if="hasName || withOrderNr" class="clearfix"/>

    <dt class="mb-0 font-italic col-4 col-md-3 col-xl-2 font-weight-light">{{$t('frontoffice.farmers.pack.order.price')}}</dt>
    <dd class="mb-0 font-italic col-8 col-md-9 col-xl-10 font-weight-light">
      <Price :amount="order.getTotalPriceWithVat()" :currency="order.getCurrency()" />, {{$t('frontoffice.farmers.pack.order.items', {nr: productsCount})}}
    </dd>
    <dt v-if="withOrderWeight" class="mb-0 font-italic col-4 col-md-3 col-xl-2 font-weight-light">{{$t('orders.list.details.total_weight')}}</dt>
    <dd v-if="withOrderWeight" class="mb-0 font-italic col-8 col-md-9 col-xl-10 font-weight-light">
      <Qty class="pl-0 pr-1" element-style="d-inline text-center col-12" :amount="order.getTotalBulk()" unit="kg" /><span>{{ $t('general.bulk') }}</span> + <Qty class="pl-0" element-style="d-inline" :amount="order.getTotalPacked()" :unit="$t('general.no_packing_bulk')" />
    </dd>
    <dt v-if="withComments" class="mb-0 font-italic col-4 col-md-3 col-xl-2 font-weight-light">{{$t('frontoffice.farmers.pack.order.comments')}}</dt>
    <dd v-if="withComments" class="mb-0 font-italic col-8 col-md-9 col-xl-10 font-weight-light">
      {{ order.getComment() ? order.getComment() : '-' }}
    </dd>
    <dt v-if="withDeliveryPreference" class="mb-0 font-italic col-4 col-md-3 col-xl-2 font-weight-light">{{$t('frontoffice.farmers.pack.order.delivery_preference')}}</dt>
    <dd v-if="withDeliveryPreference" class="mb-0 font-italic col-8 col-md-9 col-xl-10 font-weight-light">
      {{ order.getInterval() ? order.getInterval() : '-' }}
    </dd>
    <Button v-if="withDetailsModal" @click="viewDetails($event)" class="mt-3 mb-sm-0 w-100 w-sm-auto mx-sm-auto"
            name="orders.edit.view_details" type="show"/>
  </dl>
</template>

<script>
import Price from "@/components/elements/Price";
import {NumbersMixin} from "@/mixins/GeneralMixin";
import Qty from "@/components/elements/Qty";
import Button from "@/components/elements/Button";
import OrderDetailsModal from "@/components/order/frontoffice/OrderDetailsModal";

export default {
  name: "OrderSummaryCard",
  components: {
    Qty,
    OrderDetailsModal,
    Button,
    Price
  },
  mixins: [NumbersMixin],
  props: {
    order: Object,
    batch: {
      type:Number,
      default: 0,
    },
    hasName: {
      type: Boolean,
      default: true
    },
    withComments: {
      type: Boolean,
      default: true,
    },
    withDeliveryPreference: {
      type: Boolean,
      default: false,
    },
    withOrderNr: {
      type: Boolean,
      default: false,
    },
    withOrderWeight: {
      type: Boolean,
      default: false,
    },
    withDetailsModal: {
      type: Boolean,
      default: false,
    },
    withShipmentDeliveryStatus: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      orderDetailsOpen: false
    }
  },
  computed: {
    productsCount: function () {
      return this.batch === 0 ? this.order.getItemsWithQty().length : this.order.getItemsWithQtyForBatch(this.batch).length;
    },
    isDelivered() {
      if (!this.withShipmentDeliveryStatus) return false;
      return this.withShipmentDeliveryStatus.isPhaseComplete();
    },
    deliveryStatusText() {
      if (this.isDelivered) return 'orders.edit.delivered';
      else return 'orders.edit.not_delivered';
    }
  },
  methods: {
    viewDetails: function($event) {
      $event.stopPropagation();
      this.orderDetailsOpen=true;
    }
  }
}
</script>

<style scoped>

</style>
