<template>
  <Modal :custom-name="$t('orders.edit.order_number', {number: orderId})" @closemodal="closeModal" :dismissible="true">
    <template v-if="!loading && order" v-slot:default >
      <div v-if="hasDocuments" class="pb-2 mb-2">
        <h5 class="font-weight-semibold border-bottom-1 border-grey-300">{{ $t('orders.edit.invoices_available') }}</h5>
        <div v-for="(file, index) in order.getInvoice()" :key="index">
          <a class="" :href="file.url" target="_blank">{{ $t('orders.edit.download_invoice') }} #{{index+1}}</a>
        </div>
      </div>

      <h5 class="font-weight-semibold border-bottom-1 border-grey-300">{{ $t('orders.edit.products') }}</h5>
      <OrderItem v-for="item in order.getItemsWithQty()" :item="item" :key="item.getId()" />
    </template>
    <template v-else v-slot:default>
      <Loading v-if="loading" />
      <div v-else>{{$t('general.errors.fatal')}}</div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/elements/Modal";
import {NumbersMixin} from "@/mixins/GeneralMixin";
import OrderItem from "@/components/order/frontoffice/OrderItem";
import Loading from "@/components/elements/Loading";
import Order from "@/entities/Order";

export default {
  name: "OrderDetailsModal",
  components: {
    OrderItem,
    Loading,
    Modal,
  },
  emits: ['closemodal'],
  mixins: [NumbersMixin],
  props: {
    orderId: {
      type: [Number, String],
      required: true
    },
  },
  data: function () {
    return {
      loading: false,
      order: null
    }
  },
  computed: {
    hasDocuments() {
      if (!this.order) return false;
      return this.order.getInvoice() && this.order.getInvoice().length;
    }
  },
  mounted() {
    this.getOrder();
  },
  methods: {
    closeModal() {
      this.$emit('closemodal');
    },
    getOrder() {
      this.loading = true;
      this.axios.get(this.$store.state.config.getOrderUri(this.orderId)).then(
          (response) => {
            let content = response.data;
            this.order = new Order(content.data);
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(() => {
        this.loading = false;
      });
    },
  }
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>
