<template>
  <select :disabled="disabled" v-if="options.length > 0" v-model="inputVal" class="form-control">
    <option v-if="defaultOption" value="" :disabled="true" :selected="!selected">{{$t(defaultOption)}}</option>
    <option v-for="option in options" :key="option.val" v-bind:value="option.val" :selected="option.val == selected">{{$t(option.txt)}}</option>
  </select>
</template>

<script>
export default {
  name: "SelectInput",
  emits: ['input'],
  props: {
    reference: {
      type: Object,
      default: null,
    },
    selected: {
      default: "",
    },
    options: {
      type: Array,
      default: function () {
        return [];
      }
    },
    defaultOption: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.selected;
      },
      set(value) {
        this.$emit('input', this.reference, value);
      }
    }
  }
}
</script>

<style scoped>
.form-control:disabled {
  color: #333;
}
</style>